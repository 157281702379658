import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AppRoutes } from './appRoutes';
import { useReactiveVar } from '@apollo/client';
import useAuth from './useAuth';
import {
  setRedirectAfterSigninUrl,
  shouldSendAbTestToAnalytics,
  utilsStateVar,
} from 'store/utils';

const WithAuth = (Component: any) => {
  const Auth = (props: any = {}) => {
    const { isUserAuthenticated, authState } = useAuth();

    const { redirectAfterSigninUrl } = useReactiveVar(utilsStateVar);
    const shouldSendAbTestVariantToAnalytics = useReactiveVar(
      shouldSendAbTestToAnalytics
    );

    const router = useRouter();

    const { isRedirectToSubscription, orderId, subscriptionId } = router.query;

    // this is used for pages like login
    // we should not let user visit login page if he is logged in
    const { isRestricted } = props;
    const isAccountActivated = authState?.user?.isAccountActivated;
    const isAdmin = localStorage.getItem('isAdmin');

    if (isAdmin) {
      return <Component {...props} />;
    }

    if (
      isRedirectToSubscription &&
      orderId &&
      isRestricted &&
      isAccountActivated
    ) {
      shouldSendAbTestVariantToAnalytics && shouldSendAbTestToAnalytics(false);
      router.push(
        {
          pathname: AppRoutes.SUBSCRIPTIONS,
          query: { orderId: orderId as string },
        },
        AppRoutes.SUBSCRIPTIONS,
        { shallow: true }
      );
      return null;
    }

    if (
      isRedirectToSubscription &&
      subscriptionId &&
      isRestricted &&
      isAccountActivated
    ) {
      shouldSendAbTestVariantToAnalytics && shouldSendAbTestToAnalytics(false);
      router.push(`${AppRoutes.SUBSCRIPTIONS}/${subscriptionId}`);
      return null;
    }

    if (isRestricted && isAccountActivated && redirectAfterSigninUrl) {
      shouldSendAbTestVariantToAnalytics && shouldSendAbTestToAnalytics(false);
      router.push(redirectAfterSigninUrl);
      return null;
    }

    if (isRestricted && isAccountActivated) {
      shouldSendAbTestVariantToAnalytics && shouldSendAbTestToAnalytics(false);
      router.push(AppRoutes.MY_ACCOUNT);
      return null;
    }

    if (!isRestricted && !isAccountActivated && !isAdmin) {
      const url = `${window.location.origin}${router?.asPath}`;
      setRedirectAfterSigninUrl(url);
      router.push(AppRoutes.LOGIN);
      return null;
    }

    return <Component {...props} />;
  };
  // Copy getInitial props so it will run as well
  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }

  if (Component.getLayout) {
    Auth.getLayout = Component.getLayout;
  }
  return Auth;
};

export default WithAuth;
